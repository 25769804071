<template>
  <div class="statistik">
    <b-overlay :show="loading">
      <Blank v-if="paketAktif[0] == null" />
      <Tryout
        v-else
        :data="paketAktif"
        @loadKategori="(value) => handleFilterKategori(value)"
        @load="(value) => handleFilterSearch(value)"
      />
    </b-overlay>

    <!-- <Tryout /> -->
  </div>
</template>

<script>
import Blank from "./components/Blank";
import Tryout from "./components/Tryout";
import { BOverlay } from "bootstrap-vue";
export default {
  components: {
    Blank,
    Tryout,
    BOverlay,
  },

  data() {
    return {
      paketAktif: [],
      loading: false,
      q: null,
      categoriId: null,
    };
  },

  methods: {
    handleFilterSearch(val) {
      this.q = val;
      this.getDataTryoutAktif();
    },
    handleFilterKategori(val) {
      this.categoriId = val;
      this.getDataTryoutAktif();
    },
    getDataTryoutAktif() {
      this.loading = true;
      let params = {
        active: 1,
        search: this.q,
        category_id: this.categoriId,
      };
      this.$store
        .dispatch("tryout/indexMyPaket", params)
        .then((res) => {
          this.loading = false;
          this.paketAktif = res.data.data;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
  },

  created() {
    this.getDataTryoutAktif();
  },
};
</script>

<style></style>
